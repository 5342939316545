
import {defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {firebaseErrors} from "../common/methods/firebaseErrors";
import googleSignIn from "../common/methods/googleSignin";
import {getAuth, signInWithEmailAndPassword} from "@firebase/auth";
import * as Yup from "yup";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {getUser} from "@/api/user.api";
import {Category} from "@/common/enums/categoryEnum";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const loading = ref(false);
    const submitButton = ref<HTMLElement | null>(null);
    const router = useRouter();
    const store = useStore();
    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(6).required().label("Password"),
    });
    //Form submit function
    const onSubmitLogin = async (values) => {
      loading.value = true;
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      const auth = getAuth();
      signInWithEmailAndPassword(auth, values.email, values.password)
          .then(async (userCredentials) => {
            const {data} = await getUser();
            const user = {
              name: userCredentials.user.displayName,
              email: userCredentials.user.email,
              photoURL: userCredentials.user.photoURL,
              isApproved:
                  data.data && data.data.isApproved ? data.data.isApproved : false,
              category:
                  data.data && data.data.role && data.data.role.category
                      ? data.data.role.category
                      : Category.USER,
            };

            store.commit("UserModule/SET_USER_INFO", user);
            if (!user.isApproved) {
              await store.dispatch("UserModule/logoutUser");
              await store.dispatch("UserModule/resetStore");
              await store.dispatch("DiscoverModule/resetStore");
              await store.dispatch("EventsModule/resetStore");
              await store.dispatch("SpotifyAccessTokenHelperModule/resetStore");
              await router.push({name: "accountStatus"});
            } else {
              await router.push({name: "dashboardV2"});
            }
          })
          .catch((error) => {
            loading.value = false;
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            firebaseErrors(error.code);
          });
    };

    // Google sign in
    const signInWithGoogle = () => {
      googleSignIn();
    };

    return {
      login,
      loading,
      onSubmitLogin,
      signInWithGoogle,
      submitButton,
    };
  },
});
